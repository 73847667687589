import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { storeCheckoutGet } from '../../redux/modules/store'
import Box from '../Box'
import BoxMotion from '../BoxMotion'
import CenteredLogo from '../CenteredLogo'

type Props = {
  Component: any
  pageProps: any
}

const Main = (props: Props) => {
  const { Component, pageProps } = props
  const router = useRouter()

  // Store: redux
  const dispatch = useDispatch()

  // State
  const [logoVisible, setLogoVisible] = useState(true)

  // Callbacks
  // - Scroll to top after page transition
  const handleExitComplete = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0 })
    }
  }

  // Effects
  useEffect(() => {
    // Don't retain scroll position on reload
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual'
    }

    // Create checkout
    dispatch(storeCheckoutGet())

    const timeoutId = setTimeout(() => {
      setLogoVisible(false)
    }, 1500)

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      fontFamily="sans"
      minHeight="100vh"
    >
      {/* Logo (fixed) */}
      <CenteredLogo visible={logoVisible} />

      {/* Page content */}
      <AnimatePresence exitBeforeEnter onExitComplete={handleExitComplete}>
        <BoxMotion
          // framer-motion
          animate="enter"
          exit="exit"
          initial="initial"
          transition={{
            damping: 25,
            mass: 0.25,
            stiffness: 100,
          }}
          variants={{
            enter: { opacity: 1 },
            exit: { opacity: 0 },
            initial: { opacity: 0 },
          }}
          // props
          flexGrow={1}
          key={router.pathname}
          pb={3}
          pt={2}
          px={2}
          zIndex="content"
        >
          <Component {...pageProps} />
        </BoxMotion>
      </AnimatePresence>
    </Box>
  )
}

export default Main
