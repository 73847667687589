import React from 'react'
import styled from 'styled-components'

import Box from '../Box'
import Logo from '../Logo'

type Props = {
  visible?: boolean
}

const Container = styled(Box)`
  min-height: 100vh;
  min-height: -webkit-fill-available;
`

const CenteredLogo = (props: Props) => {
  const { visible } = props

  return (
    <Container
      alignItems="center"
      display={[visible ? 'flex' : 'none', null, 'flex']}
      justifyContent="center"
      left={0}
      pointerEvents="none"
      position="fixed"
      top={0}
      width="100%"
      userSelect="none"
      zIndex="centeredLogo"
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        size="160px"
      >
        <Logo />
      </Box>
    </Container>
  )
}

export default CenteredLogo
