import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    /*
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    */
    box-sizing: border-box;

    ::selection {
      background: #eee;
    }
  }

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    transition: opacity 150ms ease-out;

    &.opacity {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          opacity: 0.5;
        }
      }
    }

    &.underline {
      border-bottom: 1px solid transparent;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-bottom: 1px solid currentColor;
        }
      }
    }
  }

  body {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-size: 24px;
    font-weight: 400;
    height: 100%;
    line-height: 1.2em;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }

  html {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.white};

    font-size: 16px;
    height: 100%;
    scroll-behavior: smooth;
  }

  p {
    margin: 1em 0;
    padding: 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: 600;
  }

  #__next {
    height: 100%;
  }

  /* Hide Google reCAPTCHA badge */
  .grecaptcha-badge {
    visibility: hidden;
  }
`

export default GlobalStyle
